import { handleCartError } from "../helper";
export const addShippingAddressBase = ({ client }) => {
    return async (cartId, version, shippingAddress) => {
        const request = (version) => client.execute({
            method: "POST",
            uri: `/ctp/carts/${cartId}`,
            body: {
                version: version,
                actions: [
                    {
                        action: "setShippingAddress",
                        address: {
                            country: shippingAddress.country,
                            gender: shippingAddress.gender,
                            firstName: shippingAddress.firstName || shippingAddress.name,
                            lastName: `${shippingAddress.middleName || shippingAddress.insertion || ""} ${shippingAddress.lastName || shippingAddress.familyName}`.trim(),
                            postalCode: shippingAddress.postCode || shippingAddress.postalCode,
                            streetNumber: shippingAddress.streetNumber || shippingAddress.houseNumber,
                            streetName: shippingAddress.streetName,
                            city: shippingAddress.city,
                            additionalStreetInfo: shippingAddress.additionalStreetInfo || shippingAddress.addition,
                            phone: shippingAddress.telNum || shippingAddress.phoneNumber,
                            email: shippingAddress.email,
                            additionalAddressInfo: shippingAddress.psfKey ? shippingAddress.psfKey : "",
                        },
                    },
                ],
            },
        });
        return handleCartError(request, version, 5);
    };
};
