import { I18n, TotalPriceBase, TotalPriceWrapperBase } from "@jog/react-components";
import { styled } from "@jog/theming";
import { theme } from "layout/theme";
import React from "react";
import { Price } from "../Price/Price";
const TotalPriceWrapper = styled(TotalPriceWrapperBase) `
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 22px;
    span {
        margin-left: 10px;
        font-family: ${theme.fontFamily.primary};
        font-size: 18px;
    }
`;
export const TotalPrice = React.memo(TotalPriceBase({
    TotalLabel: () => (<div>
                <I18n t="Total"/>:
            </div>),
    TotalPriceWrapper,
    Price,
}));
