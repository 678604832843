import { getUserLocation } from "@jog/shared";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
const DEFAULT_CITY = "Netherlands";
const LocationContext = createContext({
    city: DEFAULT_CITY,
    getLocation: () => Promise.resolve(),
});
export const LocationProvider = ({ children }) => {
    const [city, setCity] = useState("");
    const getLocation = useCallback(() => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(async (position) => {
        const userLocation = await getUserLocation(position.coords);
        setCity((userLocation === null || userLocation === void 0 ? void 0 : userLocation.city) || DEFAULT_CITY);
        resolve();
    }, reject)), []);
    const value = useMemo(() => ({ city, getLocation }), [getLocation, city]);
    return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
};
const getStoreLocatorData = (inputVal) => fetch(`${process.env.NEXT_PUBLIC_STORE_API}?q=${inputVal}`)
    .then((response) => response.json())
    .catch((error) => console.log(error));
export const useLocation = (init = true) => {
    const [pending, setPending] = useState(true);
    const [stores, setStores] = useState(null);
    const onSearch = useCallback(async (value) => {
        setPending(true);
        const data = await getStoreLocatorData(value).then((res) => res.data);
        setStores(data);
        setPending(false);
    }, []);
    const { city, getLocation } = useContext(LocationContext);
    useEffect(() => {
        city && onSearch(city);
    }, [city, onSearch]);
    useEffect(() => {
        init && getLocation().catch(() => onSearch("Netherlands"));
    }, [getLocation, init, onSearch]);
    return {
        pending,
        stores,
        onSearch,
        getLocation,
        city,
    };
};
