import { recaptchaValidateToken } from "@jog/shared";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useValidateEmail } from "./useValidateEmail";
export const useNewsletter = ({ source, ls, newsletterUrl, validateTokenSuccess, }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [errorMessage, setErrorMessage] = useState(null);
    const [invalidEmailError, setInvalidEmailError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const emailInputId = `newsInput-${source}`;
    const [emailInput, setEmailInput] = useState(" ");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const replaceUrl = useMemo(() => `${newsletterUrl}?email=${encodeURIComponent(emailInput === null || emailInput === void 0 ? void 0 : emailInput.trim())}&bron=${source}`, [emailInput, newsletterUrl, source]);
    const { validateEmail } = useValidateEmail();
    const redirectToNewsletter = useCallback(async () => {
        if (!executeRecaptcha)
            return setErrorMessage("We could not validate your request please try again later.");
        setIsLoading(true);
        setIsButtonDisabled(true);
        try {
            const token = await executeRecaptcha("susbcribeToNewsletter");
            const response = await recaptchaValidateToken(token);
            const { success, score } = response === null || response === void 0 ? void 0 : response.body;
            setIsLoading(false);
            if (success && score > 0.3) {
                ls.setItem("newsletter", {
                    isSubscribed: true,
                    date: dayjs().toString(),
                });
                await (validateTokenSuccess === null || validateTokenSuccess === void 0 ? void 0 : validateTokenSuccess());
                window === null || window === void 0 ? void 0 : window.location.replace(replaceUrl);
            }
            else {
                setErrorMessage("Captcha validation was not successful");
            }
        }
        catch (error) {
            setErrorMessage("We could not validate your request please try again later.");
        }
        finally {
            setIsButtonDisabled(false);
            setIsLoading(false);
        }
    }, [executeRecaptcha, ls, replaceUrl, validateTokenSuccess]);
    const handleEmailChange = useCallback((e) => {
        /*
1. Check if the email is valid (if valid, remove any previous email error messages or add error message)
2. Add emailinput to state
3. Disable button if email is not valid
*/
        const emailInput = e.target.value;
        const isValidEmail = validateEmail(emailInput);
        setIsButtonDisabled(!isValidEmail);
        setInvalidEmailError(isValidEmail ? null : "Invalid email address");
        setEmailInput(emailInput);
    }, [validateEmail]);
    const handleFormSubmission = useCallback(async (e) => {
        e.preventDefault();
        const isValidEmail = validateEmail(emailInput);
        isValidEmail ? await redirectToNewsletter() : setInvalidEmailError("Invalid email address");
    }, [validateEmail, emailInput, redirectToNewsletter]);
    return {
        handleEmailChange,
        handleFormSubmission,
        errorMessage,
        invalidEmailError,
        isLoading,
        emailInput,
        isButtonDisabled,
        emailInputId,
    };
};
