import { PurchaseItemBase, PurchaseItemStylesBase } from "@jog/react-components";
import { styled } from "@jog/theming";
import { theme } from "layout/theme";
import React from "react";
import { Price } from "../Price/Price";
const PurchaseItemWrapper = styled(PurchaseItemStylesBase) `
    li {
        border-bottom: 1px solid ${theme.color.silverGray};
    }
    .content {
        .description {
            .order-number {
                color: ${theme.color.silverGray};
            }
        }
    }
    .more-info {
        a {
            color: ${theme.color.mainGreen};
        }
    }
`;
export const PurchaseItem = React.memo(PurchaseItemBase({
    PurchaseItemWrapper,
    Price,
}));
