import { useTheme } from "@jog/theming";
import { styled } from "@jog/theming";
import { useI18n } from "../../hooks";
import { NextImage } from "../NextImage/NextImage";
const OrderItemWrapper = styled.li `
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 30px;
    .product-image {
        img {
            width: 150px;
            height: auto;
            @media screen and (max-width: 424px) {
                width: 120px;
            }
        }
    }
    .product-description {
        padding: 0 20px;
        align-self: flex-start;
        p,
        h4 {
            font-size: 14px;
            text-transform: uppercase;
        }
        .product-details {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            div {
                margin-left: 30px;
                &:first-of-type {
                    margin-left: 0;
                }
            }
            @media screen and (max-width: 472px) {
                flex-flow: column;
                div {
                    margin-left: 0;
                }
            }
            h4 {
                margin-bottom: 2px;
            }
            p,
            h4 {
                font-size: 12px;
            }
        }
    }
    .product-price {
        .dicounted-price {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            div:first-of-type {
                margin-right: 10px;
            }
        }
    }
`;
export const OrderItemBase = ({ Price, wrapperCss }) => {
    // TODO: add placeholder image when there is no available image
    const OrderItemFC = ({ itemDetails }) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const theme = useTheme();
        const { $t } = useI18n();
        const thumbnailUrl = (_b = (_a = itemDetails.images) === null || _a === void 0 ? void 0 : _a.find((image) => (image === null || image === void 0 ? void 0 : image.format) === "thumbnail" || (image === null || image === void 0 ? void 0 : image.format) === "onmodel-front-500")) === null || _b === void 0 ? void 0 : _b.url;
        const productSize = (_d = (_c = itemDetails.variants) === null || _c === void 0 ? void 0 : _c.find((variant) => (variant === null || variant === void 0 ? void 0 : variant.qualifier) === "size")) === null || _d === void 0 ? void 0 : _d.value;
        const productLength = (_f = (_e = itemDetails.variants) === null || _e === void 0 ? void 0 : _e.find((variant) => (variant === null || variant === void 0 ? void 0 : variant.qualifier) === "length")) === null || _f === void 0 ? void 0 : _f.value;
        const productWaist = (_h = (_g = itemDetails.variants) === null || _g === void 0 ? void 0 : _g.find((variant) => (variant === null || variant === void 0 ? void 0 : variant.qualifier) === "waist")) === null || _h === void 0 ? void 0 : _h.value;
        return (<OrderItemWrapper>
                <div className="product-image">
                    <NextImage blur={false} src={thumbnailUrl || ""} alt={itemDetails.description} style={{ width: "100%" }} width={150} height={225}/>
                </div>
                <div className="product-description">
                    <p>{itemDetails.description}</p>
                    <div className="product-details">
                        {productSize && (<div>
                                <h4>{$t("Size")}</h4>
                                <p>{productSize}</p>
                            </div>)}
                        {productLength && (<div>
                                <h4>{$t("Length")}</h4>
                                <p>{productLength}</p>
                            </div>)}
                        {productWaist && (<div>
                                <h4>{$t("Waist")}</h4>
                                <p>{productWaist}</p>
                            </div>)}
                        <div>
                            <h4>{$t("Quantity")}</h4>
                            <p>{itemDetails.quantity}</p>
                        </div>
                    </div>
                </div>
                <div className="product-price">
                    {/* <sup className="regular-price">{itemDetails.regularPrice}</sup>
  <span className="paid-price">{itemDetails.paidPrice}.-</span> */}
                    {itemDetails.regularPrice === Math.abs(itemDetails.paidPrice) ? (<Price price={Math.abs(itemDetails.paidPrice)} fontSize={theme.fontSize.priceElement}/>) : (<div className="dicounted-price">
                            <Price price={itemDetails.regularPrice} isOldPrice={true} color={theme.color.shuttleGray} fontSize={theme.fontSize.label}/>
                            <Price price={Math.abs(itemDetails.paidPrice)} color={theme.color.mainRed} fontSize={theme.fontSize.priceElement}/>
                        </div>)}
                </div>
            </OrderItemWrapper>);
    };
    return OrderItemFC;
};
