import { addUserAddress, changeEmail, changePassword, checkUser, confirmForgotPassword, createUser, deleteUserAddress, forgotPassword, getLocation, getMyCart, getUserAddresses, getUserCountry, getUserData, isObjectEmpty, updateUser, updateUserAddress, } from "@jog/shared";
import { makeAutoObservable } from "mobx";
const emptyAddress = {
    city: "",
    country: "",
    familyName: "",
    gender: "",
    houseNumber: "",
    name: "",
    postalCode: "",
    streetName: "",
    uid: "",
};
export class UserStore {
    constructor() {
        this.isLoading = false;
        this.unregisteredEmail = "";
        this.userData = null;
        this.userAddress = null;
        this.userAddresses = [];
        this.userOtherAddresses = [];
        this.userCartId = "";
        this.userLocation = null;
        this.userCountry = "";
        this.isRegistrationError = false;
        this.isChangePasswordError = false;
        this.isChangePasswordSuccess = false;
        this.isChangeEmailError = false;
        this.isChangeEmailSuccess = false;
        this.isUpdateProfileError = false;
        this.isUpdateProfileSuccess = false;
        this.isLocationSupported = false;
        this.isLocationEnabled = false;
        this.isLocationError = false;
        this.locationErrorMessage = "";
        this.setLocationPermissionState = (state) => {
            switch (state) {
                case "granted":
                    this.isLocationEnabled = true;
                    break;
                case "prompt":
                    this.isLocationEnabled = false;
                    break;
                case "denied":
                    this.isLocationEnabled = false;
                    break;
            }
        };
        makeAutoObservable(this);
    }
    async getMyCart() {
        var _a;
        try {
            const userCart = await getMyCart();
            if ((_a = userCart === null || userCart === void 0 ? void 0 : userCart.body) === null || _a === void 0 ? void 0 : _a.id) {
                this.userCartId = userCart.body.id;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    /* Get the user data and store it in userData observable */
    async getUserData(updateCart) {
        try {
            const userData = await getUserData();
            userData.body.address = userData.body.address || emptyAddress;
            this.userData = userData.body;
            updateCart && (await this.getMyCart());
            return userData.body;
        }
        catch (e) {
            console.log(e);
        }
    }
    /* Check if user email exists in the system */
    async checkIfUserExists(email, token) {
        this.isLoading = true;
        try {
            const userExist = await checkUser(email, token || "");
            this.isLoading = false;
            return userExist;
        }
        finally {
            this.isLoading = false;
        }
    }
    async createNewUser(userInfo) {
        this.isLoading = true;
        try {
            await createUser(userInfo);
            this.isRegistrationError = false;
        }
        catch (e) {
            this.isRegistrationError = true;
            console.log(`CreateUser was not successful: ${JSON.stringify(e, null, 2)}`);
            console.log(e);
        }
        this.isLoading = false;
    }
    async changePassword(previousPassword, newPassword) {
        try {
            await changePassword(previousPassword, newPassword);
            this.isChangePasswordError = false;
            this.isChangePasswordSuccess = true;
        }
        catch (e) {
            this.isChangePasswordError = true;
            this.isChangePasswordSuccess = false;
            console.log(e);
        }
    }
    async updateUser(gender, firstName, middleName, lastName, birthDate, phoneNumber) {
        try {
            await updateUser(gender, firstName, middleName, lastName, birthDate, phoneNumber);
            this.isUpdateProfileError = false;
            this.isUpdateProfileSuccess = true;
            await this.getUserData(false);
        }
        catch (e) {
            this.isUpdateProfileError = true;
            this.isUpdateProfileSuccess = false;
            console.log(e);
        }
    }
    async getUserAddresses() {
        try {
            this.userAddresses = await getUserAddresses().then((res) => res.body);
            this.userAddress = this.userAddresses.find((address) => address.defaultAddress) || this.userAddresses[0];
            this.userOtherAddresses = this.userAddresses.filter((address) => { var _a; return ((_a = this.userAddress) === null || _a === void 0 ? void 0 : _a.uid) !== address.uid; });
        }
        catch (e) {
            console.log(e);
        }
    }
    async accountAddUserAddress(userAddress) {
        this.isLoading = true;
        try {
            await addUserAddress(userAddress);
            await this.getUserData(false);
            await this.getUserAddresses();
        }
        catch (error) {
            console.log(error);
        }
        this.isLoading = false;
    }
    async accountUpdateUserAddress(userAddress, addressUid, isCheckout = false) {
        try {
            await updateUserAddress(userAddress, addressUid);
            await this.getUserData(false);
            if (!isCheckout)
                await this.getUserAddresses();
        }
        catch (e) {
            console.log(e);
        }
    }
    async accountDeleteUserAddress(addressId) {
        try {
            await deleteUserAddress(addressId);
            await this.getUserData(false);
            await this.getUserAddresses();
        }
        catch (error) {
            console.log(error);
        }
    }
    sendEmailForForgotPassword(email) {
        try {
            return forgotPassword(String(email).toLowerCase());
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }
    accountConfirmForgotPassword(email, confirmationCode, password) {
        try {
            return confirmForgotPassword(String(email).toLowerCase(), confirmationCode, password);
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }
    clearRegistrationError() {
        this.isRegistrationError = false;
    }
    async changeEmailAddress(email, password) {
        try {
            await changeEmail(email, password);
            this.isChangeEmailError = false;
            this.isChangeEmailSuccess = true;
            await this.getUserData(false);
        }
        catch (e) {
            console.log(e);
            this.isChangeEmailError = true;
            this.isChangeEmailSuccess = false;
        }
    }
    removeMyAccountAlerts() {
        this.isChangeEmailError = false;
        this.isChangeEmailSuccess = false;
        this.isChangePasswordError = false;
        this.isChangePasswordSuccess = false;
        this.isUpdateProfileError = false;
        this.isUpdateProfileSuccess = false;
    }
    async getLocation(callback) {
        // check if user's browser supports Navigator.geolocation
        if (navigator.geolocation && isObjectEmpty(this.userLocation)) {
            getLocation()
                .then(async (userLocation) => {
                this.userLocation = userLocation;
                this.userCountry = userLocation.countryCode;
                this.isLocationEnabled = true;
                callback && callback(userLocation);
                return userLocation;
            })
                .catch((error) => {
                this.locationErrorMessage = error.message;
                this.isLocationError = true;
                callback && callback(null);
            });
            this.isLocationSupported = true;
        }
    }
    async getBrowserPermissions() {
        if (navigator.permissions) {
            const permissionStatus = await navigator.permissions.query({
                name: "geolocation",
            });
            this.setLocationPermissionState(permissionStatus.state);
            const setLocationPermissionState = this.setLocationPermissionState;
            permissionStatus.onchange = function () {
                setLocationPermissionState(this.state);
            };
        }
    }
    async revokeLocationPermissions() {
        this.setLocationPermissionState("denied");
    }
    async getCountry() {
        try {
            this.userCountry = await getUserCountry().then((r) => { var _a; return ((_a = (r || "")) === null || _a === void 0 ? void 0 : _a.replace(/\s/g, "")) || ""; });
            return this.userCountry;
        }
        catch (error) {
            console.log(error);
        }
    }
}
