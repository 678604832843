import { RadioTabBase, RadioTabWrapperBase } from "@jog/react-components";
import { styled } from "@jog/theming";
import { theme } from "layout/theme";
import React from "react";
// Others
const RadioWrapper = styled(RadioTabWrapperBase) `
    margin-bottom: 20px;
    padding: 16px;
    border: 1px solid ${theme.color.mediumGray};
    background: ${(props) => (props.isActive ? theme.color.backgroundGray : theme.color.white)};
    color: ${theme.color.mainGray};
    font-size: 16px;
`;
export const RadioTab = React.memo(RadioTabBase({
    RadioTabWrapper: RadioWrapper,
    tabHeaderWrapperCss: {
        ".tab-head__right-wrapper": {
            color: theme.color.mainGreen,
            ".location-icon::before": {
                fontSize: "16px",
            },
        },
        ".jc-green": {
            color: theme.color.mainGreen,
        },
    },
    distanceLabelColor: theme.color.mainGray,
}));
