// Others
import { styled } from "@jog/theming";
import React from "react";
import { useI18n } from "../../hooks";
export const RadioTabWrapperBase = styled.div `
    /* background: ${(props) => (props.isActive ? props.theme.color.backgroundGray : props.theme.color.white)}; */
`;
const TabHeadWrapper = styled.div `
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: ${(props) => (props.isActive ? "20px" : "0")};
    .tab-head__left-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        margin-right: 20px;
        cursor: pointer;
        input[type="radio"] {
            margin: 0px 7px 0 0;
            outline: none;
        }
        label {
            margin-top: 0px !important;
        }
    }
    .tab-head__right-wrapper {
        cursor: pointer;
        .location-icon::before {
            content: "\\e904";
            font-family: icomoon-jc !important;
            margin-right: 5px;
        }
    }
    label {
        margin-bottom: 0;
    }
`;
export const RadioTabBase = ({ radioTabWrapperCss, tabHeaderWrapperCss, distanceLabelColor, RadioTabWrapper, }) => {
    const RadioTabFC = ({ children, isActive, priceText, price, deliveryTimeText, id, name, changeActiveTab, isDhlAddress, shopDistance, isJcStore, storeType, }) => {
        const { $t } = useI18n();
        return (<RadioTabWrapper css={radioTabWrapperCss} isActive={isActive}>
                <TabHeadWrapper css={tabHeaderWrapperCss} isActive={isActive}>
                    <label className="tab-head__left-wrapper" htmlFor={id}>
                        <input type="radio" id={id} defaultChecked={isActive} name={name} onChange={changeActiveTab}/>
                        {isDhlAddress || isJcStore ? (<>
                                {isDhlAddress && <label htmlFor={id}>{priceText}</label>}
                                {isJcStore && (<span>
                                        <span>{storeType}</span> <span className="bold">{priceText}</span>
                                    </span>)}
                            </>) : (<label htmlFor={id}>
                                <span>{priceText} </span>
                                {price !== undefined &&
                    (price === "0.00" ? (<span className="jc-green bold">{$t("free")}</span>) : (<span className="bold">{`€ ${price}`}</span>))}
                            </label>)}
                    </label>
                    <label className="tab-head__right-wrapper" htmlFor={id}>
                        {isDhlAddress || isJcStore ? (<span css={{ color: distanceLabelColor }}>
                                <span className="location-icon"/>
                                <span>
                                    {shopDistance} {!isJcStore && "km"}
                                </span>
                            </span>) : (<span>{deliveryTimeText}</span>)}
                    </label>
                </TabHeadWrapper>
                {isActive && children}
            </RadioTabWrapper>);
    };
    return RadioTabFC;
};
