import { Price } from "@/components/Price/Price";
import { OrderItemBase } from "@jog/react-components";
import { theme } from "layout/theme";
import React from "react";
export const OrderItem = React.memo(OrderItemBase({
    wrapperCss: {
        borderBottom: `1px solid ${theme.color.silverGray}`,
        ".product-description": {
            ".product-details": {
                h4: {
                    color: theme.color.silverGray,
                },
            },
        },
    },
    Price,
}));
