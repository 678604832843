import { handleCartError } from "../helper";
export const addBillingAddressBase = ({ client }) => {
    return async (cartId, version, billingAddress) => {
        const request = (version) => client.execute({
            method: "POST",
            uri: `/ctp/carts/${cartId}`,
            body: {
                version: version,
                actions: [
                    {
                        action: "setBillingAddress",
                        address: {
                            country: billingAddress.country,
                            gender: billingAddress.gender,
                            firstName: billingAddress.firstName || billingAddress.name,
                            lastName: `${billingAddress.middleName || billingAddress.insertion || ""} ${billingAddress.lastName || billingAddress.familyName}`.trim(),
                            postalCode: billingAddress.postCode || billingAddress.postalCode,
                            streetNumber: billingAddress.streetNumber || billingAddress.houseNumber,
                            streetName: billingAddress.streetName,
                            city: billingAddress.city,
                            additionalStreetInfo: billingAddress.additionalStreetInfo || billingAddress.addition,
                            phone: billingAddress.telNum || billingAddress.phoneNumber,
                            email: billingAddress.email,
                        },
                    },
                ],
            },
        });
        return handleCartError(request, version, 5);
    };
};
