import { styled } from "@jog/theming";
import React from "react";
export const TotalPriceWrapperBase = styled.div `
    display: flex;
    justify-content: space-between;
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-weight: 500;
    color: ${(props) => props.theme.color.draculaGray};
`;
export const TotalPriceBase = ({ TotalLabel, TotalPriceWrapper, priceFontSize, Price }) => {
    const TotalPriceFC = ({ cartViewBody, children }) => {
        return (<TotalPriceWrapper>
                <TotalLabel />
                {cartViewBody && <Price fontSize={priceFontSize || "22px"} price={cartViewBody.total.centAmount}/>}
                {children}
            </TotalPriceWrapper>);
    };
    return TotalPriceFC;
};
