import { theme } from "@/layout/theme";
import { AccordionItemBase, AccordionTitleBase } from "@jog/react-components";
import classNames from "classnames";
import React from "react";
const TitleFC = ({ number, title, isActive, isStepCompleted, className, }) => {
    return (<div className={classNames("flex items-center", isActive ? "text-gray-base" : isStepCompleted ? "text-gray-base" : "text-gray-main", className)}>
            <div className={classNames("mr-3.75 h-8 w-8 text-center text-base font-semibold leading-8 text-white", isActive ? "bg-gray-base" : isStepCompleted ? "bg-green-main" : "bg-gray-main")}>
                {number}
            </div>
            <h1 className="text-base uppercase">{title}</h1>
        </div>);
};
export const AccordionTitle = React.memo(AccordionTitleBase({
    TitleFC,
    spanCss: "text-green-main font-primary font-bold",
    activeClass: "mb-10 pb-5 border-b",
}));
export const AccordionItem = React.memo(AccordionItemBase({
    AccordionTitleFC: AccordionTitle,
    defaultBgColor: theme.color.lightGreen,
    stepCompletedBgColor: theme.color.white,
}));
