import campaign from "@/lib/prismicio/prismic-campaign.json";
import { useI18n, useNewsletter } from "@jog/react-components";
import { gtmNewsletterSubscription, localStorage } from "@jog/shared";
import { AlertBox, ArrowButton, Input, LogoLoader } from "components";
import { useMemo } from "react";
export const NewsForm = ({ source }) => {
    const newsletterUrl = useMemo(() => campaign === null || campaign === void 0 ? void 0 : campaign.defaultCampaign, []);
    const { $t } = useI18n();
    const { handleEmailChange, handleFormSubmission, errorMessage, invalidEmailError, isLoading, emailInput, isButtonDisabled, emailInputId, } = useNewsletter({
        source,
        ls: localStorage,
        newsletterUrl,
        validateTokenSuccess: gtmNewsletterSubscription,
    });
    return (<form className="relative flex min-w-full flex-col items-center lg:min-w-0 lg:flex-1" onSubmit={(e) => e.preventDefault()}>
            {isLoading && <LogoLoader />}
            {errorMessage && <AlertBox isError={true} text={$t(errorMessage)}/>}

            <div className="flex w-full flex-wrap xl:flex-nowrap">
                <Input className="!mt-3 h-auto min-h-11 w-full border border-gray-silver font-primary placeholder:text-gray-silver md:!m-0 md:w-1/2 lg:w-auto" type="email" name="email" aria-label="email" value={emailInput} onChange={handleEmailChange} placeholder={$t("Your email address")} id={emailInputId} required/>
                <div className="flex w-full md:w-auto">
                    <ArrowButton buttonType="submit" className="!mt-3 w-full sm:!w-auto md:!m-0" hiddenIconOnMobile={true} disabled={isButtonDisabled || !emailInput} onClickFunction={handleFormSubmission}>
                        {$t("Register")}
                    </ArrowButton>
                    <div className="ml-5 hidden w-[140px] font-primary text-11 text-gray-silver 2xl:block">
                        {$t("We never share your email address with others.")}
                    </div>
                </div>
            </div>

            {invalidEmailError && <div className="mt-2 block w-full text-sm">{$t(invalidEmailError)}</div>}
        </form>);
};
