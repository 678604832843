import { convertCentsToEuros, getGtmIdFromCT } from "../helper";
import { gtmPush } from "./index";
export const gtmPdpProductData = (pdpData, gtmId, category) => {
    var _a, _b;
    const price = (_a = pdpData === null || pdpData === void 0 ? void 0 : pdpData.price) === null || _a === void 0 ? void 0 : _a.centAmount;
    gtmPush({
        event: "productDetail-send",
        ecommerce: {
            detail: {
                products: [
                    {
                        name: ((_b = pdpData.name) === null || _b === void 0 ? void 0 : _b.en) || "missing name",
                        id: gtmId || "missing id",
                        price: price ? parseFloat(convertCentsToEuros(pdpData.price.centAmount)) : "missing price",
                        brand: pdpData.brand || "missing brand",
                        category,
                        variant: "",
                    },
                ],
            },
        },
    });
};
export const gtmSizeChart = () => gtmPush({ event: "size_chart", eventCategory: "size_chart" });
export const gtmAddPdpProductToCart = (product, path = "") => {
    var _a;
    gtmPush({
        event: "addToCart",
        ecommerce: {
            currencyCode: "EUR",
            actionfield: { list: path },
            add: {
                products: [
                    {
                        brand: product.brand,
                        category: path,
                        id: getGtmIdFromCT(product.attributes),
                        name: ((_a = product.name) === null || _a === void 0 ? void 0 : _a.en) || "",
                        price: parseFloat(convertCentsToEuros(product.price.centAmount)),
                        variant: "",
                        quantity: 1,
                        list: "",
                    },
                ],
            },
        },
    });
};
