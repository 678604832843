// Reference about responsive images:
// https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#resolution_switching_different_sizes
import { typedBoolean } from "./helper";
const defaultMedias = {
    sm: "screen and (max-width: 575px)",
    md: "screen and (max-width: 767px)",
    lg: "screen and (max-width: 991px)",
    xl: "screen and (max-width: 1199px)",
    xxl: "screen and (max-width: 1399px)", //  xxl = 1200 - 1399
    // xxxl: 'screen and (max-width: inf); // xxxl = 1400 -  inf - illustrative only, use 'theme.mediaFrom.xxl' to target this size
};
export const responsiveImages = (mediaOptions) => {
    const medias = mediaOptions || defaultMedias;
    // Parse 'screen and (max-width: 123px)' to get '123'
    const defaultMaxScreenSizes = Object.values(medias)
        .map((media) => { var _a; return (_a = media.match(/\d+/)) === null || _a === void 0 ? void 0 : _a[0]; })
        .filter(typedBoolean); // ['575', '767', '991', '1199', '1399']
    // 'targetWidths' define the different widths that you want to provide the images with (same image, different versions)
    const getSrcset = (imgSrc, targetWidths = defaultMaxScreenSizes) => {
        const values = [];
        targetWidths.forEach((width, i) => {
            width.includes("vw") ? values.push(values[i - 1]) : values.push(`${imgSrc}&w=${width} ${width}w`);
        });
        return values.join(", ");
    };
    // 'targetWidths' should be the same as 'targetWidths' argument for 'getSrcsets' and have one more element than 'maxScreenSizes' to serve as fallback
    // 'maxScreenSizes' determines the screen conditions that you want to choose an image version for and should be ordered in ascending order (1-9)
    const getSizes = (targetWidths, maxScreenSizes = defaultMaxScreenSizes) => {
        const values = [];
        if (maxScreenSizes === undefined || maxScreenSizes.length == 0) {
            maxScreenSizes = defaultMaxScreenSizes;
        }
        maxScreenSizes.forEach((maxScreenSize, i) => {
            const targetWidth = targetWidths[i] || maxScreenSize;
            values.push(!targetWidth.includes("vw")
                ? `(max-width: ${maxScreenSize}px) ${targetWidths[i] || maxScreenSize}px`
                : `(max-width: ${maxScreenSize}px) ${targetWidths[i] || maxScreenSize}`);
        });
        // Fallback value if none of the media conditions are met
        values.push(!targetWidths[targetWidths.length - 1].includes("vw")
            ? `${targetWidths[targetWidths.length - 1]}px`
            : targetWidths[targetWidths.length - 1]);
        return values.join(", ");
    };
    return { getSizes, getSrcset, defaultMaxScreenSizes };
};
