import { AddedProductModalBase, useGate } from "@jog/react-components";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { ArrowButton } from "../ArrowButton/ArrowButton";
import { ArrowText } from "../ArrowText/ArrowText";
import { Modal } from "../Modal/Modal";
import { Price } from "../Price/Price";
const AddedProductModalBS = AddedProductModalBase({
    cartItemCss: "border-b border-b-gray-silver",
    cartItemLeftImgCss: "max-w-[150px]",
    modalItemInfoTitleCss: "hover:text-orange-main text-base text-gray-base",
    ArrowTextFC: ArrowText,
    ModalFC: Modal,
    PriceFC: Price,
    ArrowButtonFC: ArrowButton,
});
const AddedProductModalFC = (props) => {
    const { cartStore } = useStores();
    const { value: newCartPopup } = useGate("cart");
    if (newCartPopup)
        return <></>;
    return <AddedProductModalBS {...props} cartStore={cartStore}/>;
};
export const AddedProductModal = observer(AddedProductModalFC);
