import { AWSClient } from "./index";
const STORE_ID = process.env.NEXT_PUBLIC_STORE_ID;
export const usersClient = () => AWSClient(process.env.NEXT_PUBLIC_AWS_USERS_ENDPOINT || "");
export const getUserAddresses = () => usersClient()({ method: "GET", uri: "/addresses" });
export const assignCart = (anonymousCartID) => usersClient()({ method: "PUT", uri: "/cart", body: { anonymousCartID } });
export const addUserAddress = (userAddress) => usersClient()({ method: "POST", uri: "/address", body: userAddress });
export const changePassword = (previousPassword, proposedPassword) => usersClient()({ method: "PUT", uri: "/password", body: { previousPassword, proposedPassword } });
export const changeEmail = (newEmail, password) => usersClient()({ method: "PUT", uri: "/email", body: { newEmail, password } });
export const checkUser = (email, token) => usersClient()({
    method: "POST",
    uri: "/available",
    body: { token, email },
})
    .then((res) => res.body)
    .catch(async (e) => {
    var _a;
    const body = await e.response.json();
    return { available: false, message: (_a = body === null || body === void 0 ? void 0 : body.error) === null || _a === void 0 ? void 0 : _a.message };
});
export const confirmForgotPassword = (email, confirmationCode, password) => usersClient()({
    method: "POST",
    uri: "/password/confirm",
    body: { email, confirmationCode, password },
});
export const createUser = (userInfo) => {
    var _a, _b, _c, _d, _e, _f;
    const body = {
        user: {
            email: userInfo.email,
            country: userInfo.country,
            name: ((_a = userInfo.firstName) === null || _a === void 0 ? void 0 : _a.trim()) || ((_b = userInfo.name) === null || _b === void 0 ? void 0 : _b.trim()),
            insertion: ((_c = userInfo.middleName) === null || _c === void 0 ? void 0 : _c.trim()) || ((_d = userInfo.insertion) === null || _d === void 0 ? void 0 : _d.trim()),
            preferredLanguage: process.env.NEXT_PUBLIC_LOCALE,
            familyName: ((_e = userInfo.lastName) === null || _e === void 0 ? void 0 : _e.trim()) || ((_f = userInfo.familyName) === null || _f === void 0 ? void 0 : _f.trim()),
            phoneNumber: userInfo.telNum || userInfo.phoneNumber,
            gender: userInfo.gender,
            loyaltycardnumber: "",
            registrationWebshop: process.env.NEXT_PUBLIC_WEBSHOP,
            defaultAddress: {
                country: userInfo.country,
                gender: userInfo.gender,
                name: userInfo.firstName || userInfo.name,
                insertion: userInfo.middleName || userInfo.insertion,
                familyName: userInfo.lastName || userInfo.familyName,
                streetName: userInfo.streetName,
                houseNumber: userInfo.streetNumber || userInfo.houseNumber,
                addition: userInfo.additionalStreetInfo || userInfo.addition,
                postalCode: userInfo.postCode || userInfo.postalCode,
                city: userInfo.city,
                phoneNumber: userInfo.telNum || userInfo.phoneNumber,
            },
        },
        password: userInfo.password,
    };
    return usersClient()({
        method: "POST",
        uri: "/",
        body,
    });
};
export const getUserData = () => usersClient()({ method: "GET", uri: "/" });
export const getMyCart = () => usersClient()({ method: "GET", uri: `/cart?key=${STORE_ID}` });
export const updateUser = (sex, name, middleName, lastName, birthDate, phoneNumber) => usersClient()({
    method: "PUT",
    uri: "/",
    body: {
        name: name === null || name === void 0 ? void 0 : name.trim(),
        insertion: middleName === null || middleName === void 0 ? void 0 : middleName.trim(),
        familyName: lastName === null || lastName === void 0 ? void 0 : lastName.trim(),
        gender: sex,
        birthDate: birthDate,
        phoneNumber: phoneNumber,
    },
});
export const updateUserAddress = (userAddress, addressId) => usersClient()({
    method: "PUT",
    uri: `/address/${addressId}`,
    body: userAddress,
});
export const deleteUserAddress = (addressId) => usersClient()({ method: "DELETE", uri: `/address/${addressId}` });
export const forgotPassword = (email) => usersClient()({
    method: "POST",
    uri: "/password/forgot",
    body: { email },
});
export const getPurchase = () => usersClient()({ method: "GET", uri: "/transactions" });
