import styled from "@emotion/styled";
export const BillingFormWrapper = styled.div `
    font-size: ${({ fontSize }) => `${fontSize}`};
    label {
        align-self: center;
    }
    .Error {
        text-align: left;
        white-space: nowrap;
        margin-bottom: 0;
    }
    .radio-wrapper {
        display: flex;
        input[type="radio"] {
            width: 20px;
            margin-right: 10px;
            margin-top: 10px;
            font-size: 16px;
        }
        label {
            margin: 0;
        }
    }
    .register-password {
        .Error {
            white-space: normal;
        }
    }
    .billing-form-explain-text {
        margin-bottom: 22px;
        font-size: ${({ fontSize }) => `${fontSize}`};
        line-height: 1.6;
    }
    .billing-form-formik-element-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    .billing-form-submit-button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
    .tip-wrapper {
        font-family: ${(props) => props.theme.fontFamily.primary};
        font-weight: bold;
        font-size: ${({ fontSize }) => `${fontSize}`};
        margin-bottom: 10px;
        margin-top: 20px;
    }
`;
