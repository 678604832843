import { styled, useTheme } from "@jog/theming";
import { useI18n } from "../../hooks";
import { Link } from "../index";
export const PurchaseItemStylesBase = styled.ul `
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    padding: 0;
    li {
        display: flex;
        flex-flow: column nowrap;
        list-style: none;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 10px;
        padding: 10px 0;
        .content {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            .description {
                flex-grow: 2;
                flex-basis: 50%;
                p {
                    margin-bottom: 0;
                }
                .order-number {
                    font-size: ${(props) => props.theme.fontSize.itemHeader};
                }
            }
            .items {
                flex-grow: 1;
            }
            .amount {
                font-family: ${(props) => props.theme.fontFamily.primary};
                font-weight: bold;
            }
        }
        .more-info {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            text-transform: uppercase;
            font-size: 13px;
            font-family: ${(props) => props.theme.fontFamily.primary};
            font-weight: bold;
        }
    }
`;
export const PurchaseItemBase = ({ PurchaseItemWrapper, Price }) => {
    const PurchaseItemFC = ({ purchaseItem }) => {
        const theme = useTheme();
        const { $t } = useI18n();
        const purchaseLink = `/my-account/purchases/purchase-details?orderNumber=${purchaseItem.orderNumber}`;
        return (<PurchaseItemWrapper>
                <li>
                    <section className="content">
                        <div className="description">
                            <p>{purchaseItem.storeType}</p>
                            <p>{purchaseItem.date}</p>
                            <p className="order-number">{purchaseItem.orderNumber}</p>
                        </div>
                        <div className="items">
                            {purchaseItem.Items.length} {purchaseItem.Items.length > 1 ? $t("items") : $t("item")}
                        </div>
                        <div className="amount">
                            <Price price={purchaseItem.amount} fontSize={theme.fontSize.priceElement}/>
                            {/* <p>€ {purchaseItem.amount}</p> */}
                        </div>
                    </section>
                    <section className="more-info">
                        <Link to={purchaseLink}>{$t("More info")}</Link>
                    </section>
                </li>
            </PurchaseItemWrapper>);
    };
    return PurchaseItemFC;
};
