import { styled } from "../../index";
// export const HR = styled.hr<HRProps>`
//     border-top: ${(props) => props.hrSize || "1px"} solid ${(props) => props.theme.color.silverGray || "gray"};
// `
export const HRBase = ({ borderColor }) => {
    const HR = styled.hr `
        border-top: ${(props) => props.hrSize || "1px"} solid ${borderColor || "gray"};
    `;
    return HR;
};
