import { createContext, useContext, useMemo, useState } from "react";
const PendingContext = createContext({
    setPending: () => { },
});
export const PendingProvider = ({ children, Pending }) => {
    const [pending, setPending] = useState(false);
    const value = useMemo(() => ({ setPending }), []);
    return (<PendingContext.Provider value={value}>
            {pending && <Pending />}
            {children}
        </PendingContext.Provider>);
};
export const usePending = () => useContext(PendingContext);
