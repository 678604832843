import styled from "@emotion/styled";
export const FormikFormWrapper = styled.div `
    .Error {
        width: 100%;
        color: ${(props) => props.theme.color.errorRed};
        margin-top: 5px;
        margin-bottom: 10px;
    }
    select {
        appearance: none;
        outline: none;
        padding: 11px 12px;
        width: 100%;
        height: 46px;
        background: ${(props) => props.theme.color.white};
        border: ${({ errorMessage, theme }) => `1px solid ${errorMessage ? theme.color.errorRed : theme.color.lightGray}`};
        border-radius: 0;
        &:focus {
            border: 1px solid ${(props) => props.theme.color.shuttleGray};
        }
    }
    button {
        margin-right: 15px;
    }
`;
