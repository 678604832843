import { ModalBase } from "@jog/react-components";
import { theme } from "layout/theme";
import React from "react";
import { Close } from "../Close/Close";
export const Modal = React.memo(ModalBase({
    modalBoxClass: "lg:pt-5 lg:pb-5 lg:px-7.5 min-h-[300px]",
    isJC: true,
    modalTitleClass: "font-primary font-bold text-base",
    Close: ({ onClick }) => (<Close data-modal="modalbox" fontSize="17px" padding="8px 14px" bgColor={theme.color.mainGreen} onClick={onClick}/>),
}));
