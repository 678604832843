import classNames from "classnames";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useI18n } from "../../hooks";
import { Container } from "../Container/Container";
import { Link, NextImage } from "../index";
export const AddedProductModalBase = ({ cartItemCss, cartItemLeftImgCss, modalItemInfoTitleCss, ArrowTextFC, ModalFC, PriceFC, ArrowButtonFC, }) => {
    const AddedProductModalFC = ({ productSku, stlProductLink, cartStore }) => {
        var _a, _b;
        const locale = process.env.NEXT_PUBLIC_LOCALE;
        const { $t } = useI18n();
        const router = useRouter();
        const currentItem = useMemo(() => { var _a, _b, _c; return (_c = (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) === null || _c === void 0 ? void 0 : _c.find((item) => item.sku === productSku); }, [(_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems, productSku]);
        const toggleAddedProductModalShown = () => cartStore.toggleAddedProductModalShown();
        const name = useMemo(() => {
            const oriStr = (currentItem === null || currentItem === void 0 ? void 0 : currentItem.name[locale]) || (currentItem === null || currentItem === void 0 ? void 0 : currentItem.name.en) || "";
            return oriStr.slice(0, 1).toUpperCase() + oriStr.slice(1).toLowerCase();
        }, [currentItem === null || currentItem === void 0 ? void 0 : currentItem.name, locale]);
        return (<ModalFC hideModal={toggleAddedProductModalShown} height="auto" headerTitle={$t("Added to cart")} width="30%">
                <Container>
                    <div className="border-b border-b-gray-silver">
                        <ArrowTextFC text={$t("Continue shopping")} toggleVisibility={toggleAddedProductModalShown} marginBot="20px"/>
                    </div>
                    {cartStore.isCartViewLoaded && currentItem ? (<div>
                            <div className={classNames("w-full py-3.75 px-0", cartItemCss)}>
                                <div className="grid grid-cols-12 gap-2">
                                    <div className="col-span-4 self-center lg:col-span-3 xl:col-span-3">
                                        <div className="relative flex">
                                            <Link to="#" title="" className="product-image w-full text-center no-underline">
                                                <NextImage height="500" width="330" src={currentItem.image} alt="Cart item image" className={classNames("img-fluid w-full", cartItemLeftImgCss)}/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-span-4 self-center lg:col-span-5 xl:col-span-5">
                                        <div className="w-full self-center text-left lg:w-auto">
                                            {stlProductLink ? (<Link to={stlProductLink} external={!!stlProductLink} target="_blank" rel="noopener" title="" className="info-title no-underline" onClick={toggleAddedProductModalShown}>
                                                    <h6 className={classNames("m-0 w-full", modalItemInfoTitleCss)}>
                                                        {name}
                                                    </h6>
                                                </Link>) : (<Link to="#" title="" className="info-title no-underline" onClick={toggleAddedProductModalShown}>
                                                    <h6 className={classNames("m-0 w-full", modalItemInfoTitleCss)}>
                                                        {name}
                                                    </h6>
                                                </Link>)}
                                            {currentItem.promotionDescription && (<div className="info-promotion-description font-primary w-full text-13 italic 2xl:w-auto 2xl:text-sm">
                                                    {currentItem.promotionDescription[locale]}
                                                </div>)}
                                        </div>
                                        <div className="flex w-full flex-col xl:flex-row xl:gap-7.5">
                                            <div className="font-primary flex flex-col items-start text-sm font-normal 2xl:text-base">
                                                <span className="block uppercase text-gray-main">{$t("Size")}</span>
                                                <span className="font-medium">{currentItem.size}</span>
                                            </div>
                                            {currentItem.length && (<div className="font-primary flex flex-col items-start text-sm font-normal 2xl:text-base">
                                                    <span className="block uppercase text-gray-main">
                                                        {$t("Length")}
                                                    </span>
                                                    <span className="font-medium">{currentItem.length}</span>
                                                </div>)}
                                        </div>
                                    </div>
                                    <div className="col-span-4 self-center lg:col-span-4 xl:col-span-4">
                                        <div className="lg:width-auto mt-5 flex w-full flex-col flex-wrap items-center justify-start gap-2 lg:mt-0">
                                            {currentItem.prices.promotionalPrice &&
                    (currentItem.prices.firstPriceInTheCart
                        ? currentItem.prices.firstPriceInTheCart.centAmount
                        : currentItem.prices.totalPrice.centAmount) >
                        currentItem.prices.promotionalPrice.centAmount ? (<>
                                                    <PriceFC fontSize="13px" isLabelShow={false} isOldPrice={true} price={currentItem.prices.firstPriceInTheCart
                        ? currentItem.prices.firstPriceInTheCart.centAmount
                        : currentItem.prices.totalPrice.centAmount}/>
                                                    <PriceFC className="!text-red-main" fontSize="18px" marginLeft="5px" price={currentItem.prices.promotionalPrice.centAmount}/>
                                                </>) : currentItem.prices.discountPrice &&
                    currentItem.prices.firstPriceInTheCart.centAmount >
                        currentItem.prices.discountPrice.centAmount ? (<>
                                                    <PriceFC fontSize="13px" isLabelShow={false} isOldPrice={true} price={currentItem.prices.firstPriceInTheCart.centAmount}/>
                                                    <PriceFC className="!text-red-main" fontSize="18px" marginLeft="5px" price={currentItem.prices.discountPrice.centAmount}/>
                                                </>) : (<PriceFC fontSize="1em" price={currentItem.prices.firstPriceInTheCart.centAmount}/>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full justify-end py-2.5 px-0">
                                <ArrowButtonFC onClickFunction={() => {
                    toggleAddedProductModalShown();
                    router.push("/cart");
                }} className="w-auto px-8 py-2.5 text-center xl:px-12">
                                    {$t("To Order")}
                                </ArrowButtonFC>
                            </div>
                        </div>) : null}
                </Container>
            </ModalFC>);
    };
    return AddedProductModalFC;
};
