import { styled } from "@jog/theming";
import dayjs from "dayjs";
import { useI18n } from "../../hooks";
const PurchaseListSectionWrapper = styled.div `
    padding-top: 0px;
    h2.section-header {
        color: ${(props) => props.theme.color.shuttleGray};
        text-transform: uppercase;
    }
    hr {
        margin-bottom: 10px;
    }
`;
export const PurchaseListSectionBase = ({ PurchaseItem, Section }) => {
    const PurchaseListSectionFC = ({ purchaseList, children }) => {
        // sort by date
        const sortedPurchases = [...purchaseList].sort((a, b) => dayjs(b.date, dayjs().format("DD-MM-YYYY")).unix() - dayjs(a.date, dayjs().format("DD-MM-YYYY")).unix());
        const { $t } = useI18n();
        return (<PurchaseListSectionWrapper>
                <Section title={$t("My Purchases")}>
                    {children}
                    <ul>
                        {sortedPurchases.map((purchase) => (<PurchaseItem key={purchase.id} purchaseItem={purchase}/>))}
                    </ul>
                </Section>
            </PurchaseListSectionWrapper>);
    };
    return PurchaseListSectionFC;
};
