import { theme } from "@/layout/theme";
import { ErrorBoundaryBase, useI18n } from "@jog/react-components";
import React from "react";
const ErrorBoundaryFC = ({ system, children }) => {
    const { $t } = useI18n();
    return (<ErrorBoundaryBase theme={theme} errorGeneral={$t("Error general:")} tryAgain={$t("Something went wrong please try again")} system={system}>
            {children}
        </ErrorBoundaryBase>);
};
export const ErrorBoundary = React.memo(ErrorBoundaryFC);
