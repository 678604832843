import styled from "@emotion/styled";
export const DhlDeliveryWrapper = styled.div `
    margin-bottom: 20px;
    .post-code-wrapper {
        display: flex;
        margin-bottom: 20px;
        input {
            flex-grow: 1;
            vertical-align: top;
            padding: 11px 12px;
            height: 46px;
            outline: none;
            border: 1px solid ${(props) => props.theme.color.mediumGray};
            margin-bottom: 0;
            &:focus {
                border: 1px solid ${(props) => props.theme.color.shuttleGray};
            }
            &:disabled {
                background: ${(props) => props.theme.color.backgroundGray};
            }
            &::placeholder {
                color: ${(props) => props.placeholderColor};
            }
        }
        button {
            flex-grow: 1;
            white-space: nowrap;
        }
        @media ${(props) => props.theme.media.sm} {
            flex-wrap: wrap;
            input,
            button {
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }
`;
